.main-container {
  --pages-layout-distance: 0px;
  width: 100%;
  min-width: 280px;
  height: 100vh;
  height: calc(100vh - var(--pages-layout-distance));
  background-color: #f6f6f6;

  .body-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - var(--pages-layout-distance));
    padding: 0px;
    overflow: hidden;
    margin-top: var(--pages-layout-distance);

    @media screen and (width >= 769px) {
      --pages-layout-distance: 48px;
    }

    .scroll-container {
      --ml-padding-bottom: 110px;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 10px;
      padding-top: 0px;
      padding-bottom: var(--ml-padding-bottom);

      @media screen and (width >= 769px) {
        padding: 32px;
        --ml-padding-bottom: 10px;
      }
    }
  }
}
