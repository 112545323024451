.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  gap: 32px;
  padding-top: 100px;

  @media screen and (width >= 769px) {
    padding-top: 20px;
  }

  .title--container {
    display: flex;
    flex-direction: row;
    gap: 32px;
    align-items: center;

    .title--text {
      font-size: 24px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.30000001192092896px;
    }
  }

  .body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media screen and (width >= 769px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .buttons-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: clamp(220px, 100%, 320px);
    }
  }
}
