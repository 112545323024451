.avatar-container {
  width: max-content;
  border: 1px solid #f5e1e5;
  border-radius: 50px;
  padding: 8px 12px;
  background-color: rgba(245, 225, 229, 0.2);

  box-shadow: 0px 2px 5px color-mix(in srgb, #000 10%, transparent),
    0px 4px 10px color-mix(in srgb, #000 10%, transparent);
  cursor: pointer;
  transition: box-shadow 0.15s ease-in, scale 0.15s ease-in, background 0.15s ease-in;

  &:hover {
    /* box-shadow: 0px 3px 7.5px color-mix(in srgb, #000 12%, transparent),
      0px 6px 15px color-mix(in srgb, #000 12%, transparent);
    scale: 1.001; */
    background-color: rgba(245, 225, 229, 0.4);
  }

  &:active {
    box-shadow: 0px 1px 2.5px color-mix(in srgb, #000 12%, transparent),
      0px 2px 5px color-mix(in srgb, #000 12%, transparent);
    scale: 0.999;
    transition: box-shadow 0.05s ease-in, scale 0.05s ease-in;
  }
}

.tutor-name {
  display: none;

  @media screen and (width > 800px) {
    display: flex;
  }
}
