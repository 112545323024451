.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
  width: 100%;

  .input-container {
    width: fit-content;

    &.input-container__editing {
      width: 100%;
    }

    .label {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: var(--color-secondary);

      max-width: 250px;
    }

    .content {
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
      text-align: left;
      overflow-wrap: break-word;
    }

    .input {
      margin-top: 3px;
      border-radius: 34px;
      width: 100%;
      height: 42px;
      border: 1px solid #cccccc;
      background-color: white;
      font-weight: 300;
    }
  }
}
