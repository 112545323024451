.option_container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;

  .flag {
    width: 24px;
    height: 100%;
  }
  @media screen and (width <= 420px) {
    .flag {
      width: 20px;
    }
    p {
      font-size: 14px;
    }
  }
}
