.modal {
  width: min(50rem, 95vw);
  min-width: 270px;
}

input[type='file']::file-selector-button {
  border: 2px solid #6c5ce7;
  padding: 0.2em 0.4em;
  border-radius: 0.9em;
  background: linear-gradient(92.78deg, #30357b 18.94%, #d06e80 94.82%);
  transition: 1s;
  color: white;
}

input[type='file']::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
}

.title-icon {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  margin-left: 5px;
}

.modal {
  display: flex;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  z-index: 50;
  /* box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%); */
}

.modal-Small {
  display: contents;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  z-index: 50;
}

.edit-modal-scale {
  margin-top: 15px;
  font-size: 25px;
}

.tiny-screen-yes-no-buttons-width {
  width: 125%;
}
