.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 280px;
  height: fit-content;
  background-color: #f6f6f6;

  .inner-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: fit-content;
    padding-top: 100px;

    @media screen and (width >= 769px) {
      padding-top: 20px;
    }

    .top-navbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  .tutor-info--container {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .tutor-info--card {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 16px;
      width: clamp(220px, 100%, 400px);
      padding: var(--popup-padding);
      border-radius: var(--border-radius-large);
      background-color: #ffffff;
      box-shadow: 0 3px 7px color-mix(in srgb, #000 10%, transparent),
        0 4px 10px color-mix(in srgb, #000 10%, transparent);

      @media screen and (width >= 769px) {
        /* flex-direction: row; */
      }

      &.tutor-info--card__editing {
        flex-direction: column;
      }
    }
  }
}
