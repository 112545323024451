.notification-container {
  width: fit-content;
}
.card-header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
  justify-content: space-between;
  text-wrap: nowrap;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.date-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
