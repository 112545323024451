@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'App.css';
@import url('./variables');
@import url('./global-classes.css');

html,
body {
  margin: 0;
  font-family: 'Comfortaa', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f680;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#message {
  display: none;
}

@media screen and (max-width: 935px) and (max-height: 430px) {
  #root {
    display: none;
  }

  #message {
    display: block;
  }

  body {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .message {
    font-weight: 100;
    font-size: 18px;
    text-align: center;
  }

  .message::after {
    content: 'Por favor, ponga su móvil en modo vertical para ver el contenido.';
  }
}
