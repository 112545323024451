.top_menu_container {
  /* position: fixed; */
  /* height: 114px; */
  padding: 17px 30px;
  width: 100%;
  /* z-index: 20; */

  @media screen and (width < 769px) {
    padding-block: 10px;
    box-shadow: 15px 4px 10px color-mix(in srgb, #000 10%, transparent),
      0 6px 15px color-mix(in srgb, #000 10%, transparent);
  }
}
.cart-icon-container {
  width: 48px;
  height: 48px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 0px 10px #c5c5c5;
}

.bg-gray {
  background-color: #f6f6f6;
}

.notification-advice {
  position: absolute;
  top: -5px;
  right: -5px;
  padding: 13px;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  background-color: color-mix(in srgb, red 20%, #fff);
  border-radius: 50%;
}

.notification-advice-100 {
  font-size: 9px;
}

.notification-advice p {
  margin-top: 2px;
}

.search-input-container {
  border: 1px solid #dddddd;
  border-radius: 50px;
  padding: 2px 14px;
}

.search-input {
  border: none;
}

.title-icon {
  width: 24px;
  height: 24px;
  margin-top: 2px;
  margin-left: 5px;
}

.top-menu-title {
  font-size: 18px;

  @media screen and (width > 321px) {
    font-size: 19px;
  }

  @media screen and (width > 1100px) {
    font-size: 26px;
  }
}
