.Help-Title-1 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.30000001192092896px;
  text-align: center;
}

.Help-Title-1-small {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.5px;
}

.Help-Title-2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
}

.width-85 {
  width: 85%;
}
