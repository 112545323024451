.report-card {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 32px;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 3px 7.5px color-mix(in srgb, #000 10%, transparent),
    0px 4.5px 12px color-mix(in srgb, #000 10%, transparent);

  .fields-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: baseline;
    justify-content: start;

    @media screen and (width > 530px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .media-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .image-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      gap: 16px;

      .image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media screen and (width > 530px) {
      flex-direction: row;
    }
  }
}
