.factura-container {
  background-color: white;
  border-radius: 8px;
  padding: 9px;
  box-shadow: 0px 0px 12px #eeeeee;
}

.factura-text-small {
  font-size: 10px;
}

.download-link {
  color: rgba(40, 51, 125, 1);
  border-bottom: 1px solid rgba(40, 51, 125, 1);
}
