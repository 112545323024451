.container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .buttons-container {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
