.main-login-container {
  --login-border-radius: 16px;
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-color: color-mix(in srgb, #b2637f 8%, #fff);
  overflow: scroll;

  .login-wrapper {
    display: grid;
    place-items: center;
    height: 100%;
    padding-top: 100px;
    z-index: 1;

    .login-container {
      display: flex;
      /* display: none; */
      position: relative;
      width: min(60rem, 98vw);
      min-width: 280px;
      box-shadow: 0 8px 16px color-mix(in srgb, #000 10%, transparent),
        0 4px 8px color-mix(in srgb, #000 10%, transparent);

      border-radius: var(--login-border-radius);
      background-color: #ffffff;

      .video-container {
        width: 38%;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        overflow: hidden;

        .video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .login-textContent-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        width: 62%;
        height: fit-content;
        padding: 1rem 2rem;

        .login-title-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          .lura-care-logo {
            display: none;
            height: 35px;
            min-width: 100px;
            width: fit-content;
          }

          .login-text {
            color: #28337d;
            font-size: 35px;
            font-weight: bold;

            @media screen and (width <= 700px) {
              font-size: 27px;
              line-height: 130%;
              letter-spacing: -1.5px;
            }
          }
        }

        .login-signup-body-container {
          width: 100%;
        }
      }
    }
  }

  @media screen and (width <= 700px) {
    .login-wrapper {
      .login-container {
        flex-direction: column;
        position: absolute;
        top: 0;
        width: 100vw;
        min-height: 100vh;
        min-height: 100svh;
        border-radius: 0px;
        border-bottom-left-radius: var(--login-border-radius);
        border-bottom-right-radius: var(--login-border-radius);
        z-index: 1;

        .video-container {
          border-radius: 0px;
          width: auto;
          height: 20vh;
          overflow: visible;
          z-index: -1;

          .video {
            margin-inline: auto;
            height: 130%;
          }
        }

        .login-textContent-container {
          width: 100%;
          height: 100%;
          padding-bottom: 0px;
          background-color: #fff;
          border-radius: var(--login-border-radius);
          z-index: 1;

          .lura_care_logo {
            display: inline-block;
          }
        }
      }
    }
  }
}
