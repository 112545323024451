.top-menu--container {
  position: fixed;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 280px;
  background-color: #fff;

  padding: 10px;

  box-shadow: 15px 4px 10px color-mix(in srgb, #000 10%, transparent),
    0 6px 15px color-mix(in srgb, #000 10%, transparent);

  z-index: 20;

  @media screen and (width >= 769px) {
    width: calc(100% - 180px);
    padding-inline: 16px;
    border-bottom-width: 1px;
    box-shadow: none;
  }

  .login-register-btns {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
