.main-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  min-height: 100%;

  .inner-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 100px;
    flex: 1;

    @media screen and (width >= 1024px) {
      margin-top: 0px;
    }

    .btns-image-container {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 40px;

      @media screen and (width >= 1024px) {
        flex-direction: row;
      }

      .btns-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.30000001192092896px;
  text-align: center;

  @media screen and (width >= 769px) {
    font-size: 20px;
  }
}

.img-container {
  display: none;
  width: 100%;
  overflow: hidden;

  @media screen and (width >= 1024px) {
    display: block;
  }

  .img {
    width: 397px;
    height: 100%;
    object-fit: cover;
  }
}
