.navbar_container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  /* border-right: 2px solid silver; */
  border-right-width: 2px;
  background-color: #fff;
  z-index: 30;

  box-shadow: 1px 8px 10px color-mix(in srgb, #000 10%, transparent),
    2px 10px 15px color-mix(in srgb, #000 5%, transparent);

  @media screen and (width < 769px) {
    display: none;
  }
}

.logo-padding {
  padding: 1.2rem 0.5rem;
}
/* @media screen and (max-device-width: 480px) {
    #navbar {
        display: none;
    }
} */

.navbar_lc_logo_container {
  display: grid;
  place-content: center;

  .navbar_lc_logo {
    height: 53px;
    /* width: 182px; */
    width: 100%;
    /* height: fit-content; */
  }
}

.option {
  border-radius: 6px;

  &.option-active {
    color: '#28337D';
    background: '#F5E1E580';
    width: 150;
  }

  &:hover {
    background-color: color-mix(in srgb, #f5e1e580 50%, transparent);
  }
}
