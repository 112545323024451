.main-container {
  width: 100%;
  background-color: #fff;
  padding: 20px;

  @media screen and (min-width: 769px) {
    background-color: unset;
    display: flex;
    justify-content: center;
  }

  .title--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;

    .title--text {
      font-size: 24px;
      color: #000;
    }
  }

  .body-container-main {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 100px;
    gap: 16px;

    @media screen and (min-width: 769px) {
      align-items: flex-start;
      height: fit-content;
      padding-top: 0;
    }
  }

  .body-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: clamp(220px, 100%, 600px);
    padding: 32px;
    background-color: white;
    border-radius: var(--border-radius-medium);

    .details-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: fit-content;
      padding: 16px;
      border: 1px solid #e0e0e0;
      border-radius: var(--border-radius);
      background-color: #fafafa;

      .title {
        color: var(--color-secondary);
        font-size: 18px;
        font-weight: 500;
      }
    }

    .buttons-container {
      display: flex;
      gap: 16px;
      flex-direction: column;
      width: 100%;
    }
  }

  .form-container {
    width: 100%;

    @media screen and (min-width: 769px) {
      width: 50%;
    }
  }

  .text-red {
    color: #eb6d81;
  }

  .message-container {
    width: 90%;
    margin-top: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    background-color: #fff;
  }

  .message-container-text {
    width: 100%;
    text-align: center;
    font-size: larger;
    font-weight: 900;
  }
}
