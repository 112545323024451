.button {
  display: flex;
  flex-direction: row;
  position: relative;
  height: fit-content;
  width: fit-content;
  text-wrap: nowrap;
  border-radius: var(--border-radius);
  font-size: 16px;

  &.primary {
    --bg-color-1: var(--color-primary);
    --bg-color-2: var(--color-secondary);
    --color: #fff;
    background: linear-gradient(92.78deg, var(--bg-color-1) 18.94%, var(--bg-color-2) 94.82%);
    color: var(--color) !important;

    &:disabled {
      --bg-color-1: color-mix(in srgb, var(--color-primary) 40%, #cccccc);
      --bg-color-2: color-mix(in srgb, var(--color-secondary) 40%, #cccccc);
      --color: color-mix(in srgb, #fff 90%, #5e5e5e);
    }
  }

  &.secondary {
    --color: var(--color-primary);
    background: none;
    color: var(--color) !important;
    border: 1px solid var(--color);

    &:disabled {
      --color: color-mix(in srgb, var(--color-primary) 20%, #cccccc);
    }
  }

  &.tertiary {
    --color: var(--color-secondary);
    --bg-color: #fff;
    background-color: var(--bg-color);
    border: 1px solid var(--color);
    color: #000;

    &:disabled {
      --color: color-mix(in srgb, var(--color-secondary) 20%, #cccccc);
      --bg-color: color-mix(in srgb, #fff 90%, #5e5e5e);
    }
  }

  &.onlyText {
    background: none;
    color: #28337d !important;
    border: none;

    &:disabled {
      color: color-mix(in srgb, #28337d 40%, #cccccc) !important;
    }
  }

  &.error {
    background: none;
    color: #fc0e0e !important;
    border: 1px solid #fc0e0e;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
