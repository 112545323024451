.card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #eeeeee;

  @media screen and (width > 768px) {
    padding: 2rem;
  }

  .card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
  }

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: left;

    @media screen and (width > 768px) {
      padding-block: 15px;
    }

    .title-container--text {
      font-size: 18px;
      font-weight: 900;
      line-height: 28px;
      color: #1e1c1c;
    }
  }

  .icons-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
    gap: 16px;
  }

  .notification-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 12px;
    height: 12px;
    border-radius: 9999px;
    background-color: #dc2636;
  }
}
