.blog-height {
  height: 100%;
}

.Blog-Title {
  font-family: Comfortaa;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -1.5px;
  text-align: left;
}

.Blog-Text {
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;
  color: rgb(13, 23, 59);
}

.item-blog {
  border-radius: 12px;
}
