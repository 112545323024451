.tratamiento-container {
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 14px;
}

.pagos-height {
  height: calc(100vh - 165px);
  overflow: scroll;
}

.pagos-height-access {
  height: calc(100vh - 115px);
  overflow: scroll;
}

.services-height {
  max-height: calc(100vh - 294px);
  overflow: scroll;
}

.services-height-med {
  max-height: calc(100vh - 390px);
  overflow: scroll;
}
.pedidos-height {
  height: calc(100vh - 353px);
  overflow: scroll;
}

.suscriptions-height {
  height: calc(100vh - 310px);
  overflow: scroll;
}

.informe-button p {
  font-size: 12px;
}

.servicios-container {
  border: 1px solid #d6d6e5;
  border-radius: 8px;
}

.item-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.item-image-pedido {
  width: 125px;
  height: 125px;
  object-fit: contain;
}

/* /PAGO RESUMEN*/
.pago-resumen {
  width: 596px;
  margin: 0px auto;
}

.pago-resumen-container {
  border: 1px solid #d6d6e5;
  border-radius: 12px;
}

.pago-resumen-buttons-container {
  width: 596px;
}

.width-600 {
  width: 600px;
}

.checkbox-check-properties {
  color: #0dd345;
  top: -2px;
  right: -2px;
}

.checkbox-white-properties {
  top: -2px;
  right: -2px;
}

.titulo-small {
  font-size: 18px;
}

.subtitulo-small {
  font-size: 12px;
}

.valor-small {
  font-size: 14px;
}

.button-small {
  font-size: 10px;
}

.next-deliver {
  margin-left: 120px;
}
