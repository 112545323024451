.main-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 1.25rem;
  padding-top: 100px;

  @media screen and (width >= 769px) {
    padding-top: 1.25rem;
  }

  .title--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;

    .title--text {
      font-size: 24px;
      font-weight: 500;
      color: #000;
    }
  }

  .inner-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .visits-menu {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: center;
      gap: 48px;
      width: 100%;
      min-width: 220px;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      border-bottom: 1px solid #f5e1e5;

      @media screen and (width >= 769px) {
        flex-direction: row;
      }

      .request-service-btn {
        margin-left: auto;
      }
    }
  }
}
