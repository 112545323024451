.item-card--container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: clamp(270px, 100%, 500px);
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: var(--border-radius-medium);
  padding: 14px;

  .item-card--container--header {
    display: flex;
    justify-content: space-between;
  }

  .item-card--container--body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .details-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, 180px);
      gap: 16px;
      justify-content: space-between;
    }
  }
}
