.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.date-cvc-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (width >= 435px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  .label {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--color-secondary);

    max-width: 250px;
  }

  .content {
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    overflow-wrap: break-word;

    &.error {
      color: red;
    }
  }

  .input {
    padding: 16px 24px;
    border-radius: var(--border-radius);
    border: 1px solid #cccccc;
    background-color: white;
    font-weight: 400;

    &.input--cvc {
      width: 80px;
    }

    &.input--date {
      width: 105px;
    }
  }
}
