.sign-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
  min-width: 280px;
  height: fit-content;

  @media screen and (width <= 700px) {
    margin-top: 20px;
    height: 100%;
  }
}

.iframe-container {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}
