.main-container {
  width: 100%;

  .inner-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    padding: 20px;
    padding-top: 100px;

    @media screen and (width >= 980px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (width >= 769px) {
      padding-top: 20px;
    }

    .services-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      .services-title--container {
        display: flex;
        flex-direction: row;
        gap: 30px;
      }

      .services-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, clamp(210px, 100%, 350px));
        gap: 16px;
      }
    }

    .patient-info-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
    }
  }
}
