.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .inner-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    margin: 8px;
    margin-top: 90px;

    @media screen and (width >= 769px) {
      margin-top: 8px;
    }

    .sign-form-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      place-items: left;
      width: 100%;
      height: 100%;
      padding: 16px;
    }
  }
}
