.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: min(400px, calc(100vw - (var(--popup-padding) * 2)));
  min-width: calc(280px - (var(--popup-padding) * 2));
  max-width: 500px;
  flex-grow: 1;

  .inputs-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .button-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    width: 100%;

    @media screen and (width > 400px) {
      flex-direction: row;
    }

    & > * {
      width: 100%;
    }
  }
}
