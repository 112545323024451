.sign-input--container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  .sign-input--label {
    display: block;
    color: #b2637f;
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
  }

  .sign-input--input {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: var(--border-radius);
    padding: 18px;
    border: 1px solid #cccccc;
    background-color: white;
    font-weight: 300;

    &.disabled {
      background-color: #f5f5f5;
    }
  }
}
