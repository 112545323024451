.dropdown {
  --dropdown-background-color: color-mix(in srgb, #fff 70%, transparent);
  --dropdown-outline-color: #dddcdc;
  --dropdown-selected-color: color-mix(
    in srgb,
    var(--dropdown-background-color),
    var(--color-secondary) 28%
  );
  --dropdown-border-radius: 16px;
  --dropdown-padding: 13px;

  
  
  @media screen and (width <= 420px) {
    --dropdown-padding: 10px;
    font-size: 14px;
  }

  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  color: #000;
  font-size: 16px;

  &.dropdown__large {
    width: clamp(240px, 100%, 460px);
  }

  .content_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    transition: background-color 0.1s ease-in, outline 0.1s ease-in;
    overflow: hidden;
  }

  .dropdown_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    background-color: var(--dropdown-background-color);
    /* background-color: aqua; */
    padding: var(--dropdown-padding);
    border-radius: var(--dropdown-border-radius);
    outline: 1px solid var(--dropdown-outline-color);
    backdrop-filter: blur(8px);
    cursor: pointer;
    &.dropdown_header-open,
    &:hover {
      outline: 3px solid var(--dropdown-selected-color);
    }
  }

  .dropdown_list {
    position: absolute;
    right: 0;
    top: calc(100% + 5px);
    display: flex;

    flex-direction: column;
    /* gap: 2px; */
    max-height: 240px;
    overflow-y: scroll;
    border-radius: var(--dropdown-border-radius);
    width: 100%;
    background-color: var(--dropdown-background-color);
    border: 1px solid var(--dropdown-outline-color);
    backdrop-filter: blur(8px);
    z-index: 20;

    &.dropdown_list-hidden {
      display: none;
    }
  }

  .dropdown_item {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: var(--dropdown-padding);
    cursor: pointer;
    height: fit-content;
    &:hover {
      background-color: color-mix(in srgb, var(--dropdown-background-color), #000 4%);
    }

    &.dropdown_item-selected {
      background-color: var(--dropdown-selected-color);
    }
  }

  .search-container {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    cursor: text;
    z-index: 20;

    .magnifying-glass {
      position: absolute;
      top: 50%;
      translate: 0 -50%;
      left: 16px;
    }

    .search-input {
      padding-left: 48px;
    }
  }
}
