.signup-form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: fit-content;

  @media screen and (width <= 700px) {
    height: 100%;
  }
}

.error-text {
  color: red;
}
