.main-container {
  width: 100%;
  height: 100%;

  .patients-list {
    display: flex;
    flex-direction: row;
    gap: 32px;
    padding: 20px;
    width: 100%;
    min-width: 280px;

    @media screen and (width < 769px) {
      flex-direction: column;
      padding-bottom: 10px;
      padding-inline: 8px;
    }

    .patients-list__container {
      width: 100%;
      height: 100%;
      overflow: scroll;

      @media screen and (width < 769px) {
        margin-bottom: 96px;
      }
    }
  }
}
