.main-container {
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    padding: 20px;
    padding-top: 100px;

    @media screen and (width >= 769px) {
      padding-top: 20px;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 48px;
      width: 100%;
      padding-bottom: 250px;

      .patients-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .info-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
        text-align: center;

        .info {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
        }
      }
    }
  }
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .title {
    font-size: 20px;
    font-weight: 800;
  }
  .care-home-name {
    font-size: 22px;
    font-weight: 800;
  }
}

.warning-text {
  color: #ff8c00;
  border: 1px dashed currentColor;
  padding: 16px;
  border-radius: var(--border-radius);
  background-color: color-mix(in srgb, currentColor 5%, #fff);
}

.confirm-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 500px;

  background-color: #fff;
  padding: 32px;
  border-radius: var(--border-radius-medium);
  width: fit-content;
  justify-content: center;

  .date-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: center;

    .date-input {
      padding: 16px;
      border-radius: var(--border-radius);
      border: 1px solid #cccccc;
      width: fit-content;
    }
  }
}
