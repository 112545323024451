.numeric-input-no-arrow {
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
}

.bs-10 {
  box-shadow: 0px 8px 10px color-mix(in srgb, #000 10%, transparent),
    0px 10px 15px color-mix(in srgb, #000 5%, transparent);
}

.bs-20 {
  box-shadow: 0px 6px 10px color-mix(in srgb, #000 10%, transparent),
    0px 8px 15px color-mix(in srgb, #000 5%, transparent);
}

.bs-30 {
  box-shadow: 0px 1.3px 7px color-mix(in srgb, #000 10%, transparent),
    0px 3px 10px color-mix(in srgb, #000 5%, transparent);
}
