.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (width > 550px) {
    flex-direction: row;
  }

  .button {
    margin-left: auto !important;
  }
}
