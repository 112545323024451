.login-text-small {
  color: #28337d;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -1.5px;
}

.login-button {
  color: white;
  padding: 13px;
  margin-top: 1rem;
  border-radius: 34px;
  background: linear-gradient(137deg, rgba(48, 53, 123, 1) 0%, rgba(208, 110, 128, 1) 100%);

  &.login-button-gray {
    color: white;
    padding: 13px;
    border-radius: 34px;
    background: gray;
  }
}

.resend-container {
  width: 400px;
  margin: auto;
}

.resend-button {
  color: white;
  padding: 13px;
  margin-top: 42px;
  border-radius: 34px;
  background: linear-gradient(137deg, rgba(48, 53, 123, 1) 0%, rgba(208, 110, 128, 1) 100%);
}

.forgot-password {
  color: #28337d !important;
  text-align: right;
  margin-top: 12px;
  cursor: pointer;
}

.forgot-text {
  color: #333333;
  font-weight: 300;
}
.forgot-link {
  color: #28337d !important;
  cursor: pointer;
}

.purple-text {
  color: #b2637f !important;
  font-size: 18px;
  font-weight: 400;
}

.input-container-desktop {
  width: 462px;
  margin: auto;
}
