.main-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-height: 100%;
  width: fit-content;
  padding-bottom: 32px;

  .container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding-top: 100px;

    @media screen and (width >= 769px) {
      padding-top: 20px;
    }

    .title {
      font-size: 24px;
      color: black;
    }

    .items-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
