.signup-form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: fit-content;
}

.error-text-checkbox {
  color: red;
  font-size: 12px;
}

.checkbox-container {

  width: 90%;
  .checkbox-container-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .checkbox-container-paragraph{
      margin-right: 15px;
      width: fit-content;
    }
    .paragraph{
      margin-right: 15px;
    }

    .paragraph-small{
      margin-right: 15px;
      font-size: 10px;
      color: var(--color-primary)
    }

    .link{
      color: var(--color-secondary)
    }
    .link:hover{
      cursor: pointer;
    }
    .custom-checkbox {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      position: relative;
    }

    .error-text-checkbox {
      color: red;
      font-size: 8px;
    }
    
    .checkbox {
      display: none;
    }
    
    .custom-checkmark {
      width: 20px;
      height: 20px;
      border: 2px solid var(--color-secondary);
      border-radius: 3px;
      background-color: white;
      transition: all 0.3s ease;
    }
    
    .checkbox:checked + .custom-checkmark {
      background-color: var(--color-secondary);
      border-color: var(--color-primary);
      position: relative;
    }
    
    .checkbox:checked + .custom-checkmark::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }

  }
}

@media screen and (width <= 700px) {
  .signup-form {
    height: 100%;
  }
}
