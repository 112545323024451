.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 275px;
  padding: 20px;
  gap: 32px;
  padding-top: 100px;

  @media screen and (min-width: 769px) {
    display: flex;
    justify-content: start;
    flex-direction: column;
    width: 100%;
    padding-top: 20px;
  }

  .title--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    gap: 32px;

    .title--text {
      font-size: 24px;
      font-weight: 400;
      color: #000;
    }
  }

  .body-container-main {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 32px;

    @media screen and (min-width: 769px) {
      display: block;
      height: fit-content;
      width: 100%;
    }
  }

  .body-container {
    width: clamp(220px, 100%, 450px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    align-content: left;
    gap: 32px;
    background-color: white;
    padding: 32px;
    border-radius: var(--border-radius-medium);
    margin-inline: auto;

    @media screen and (width >= 769px) {
      margin: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .button-container {
      width: 60%;
    }
  }

  @media screen and (min-width: 769px) {
    .button-container {
      width: 100%;
    }
  }

  .form-container {
    width: 100%;
  }
}
