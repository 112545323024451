.login-input--container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .login-input--label {
    display: block;
    color: var(--color-secondary);
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
  }

  .login-input--input {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: var(--border-radius);
    padding: 18px;
    border: 1px solid #cccccc;
    background-color: white;
    font-weight: 300;

    &.disabled {
      background-color: #f5f5f5;
    }
  }

  .error-message {
    color: red;
    font-size: 14px;
  }
}
