.main-container {
  width: 100%;
  min-width: 275px;
  display: flex;
  padding: 20px;
  padding-top: 100px;

  @media screen and (min-width: 769px) {
    background-color: unset;
    display: block;
    padding-top: 20px;
  }

  .body-container {
    display: flex;
    flex-direction: column;
    background-color: #f7f7f7;
    gap: 32px;

    .title--container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px;

      .title--text {
        font-size: 24px;
        font-weight: 500;
        color: #000;
      }
    }

    .body-container-main {
      display: grid;
      grid-template-columns: repeat(auto-fit, clamp(220px, 100%, 550px));
      gap: 16px;

      .form-container {
        display: flex;
        flex-direction: column;
        height: fit-content;
        gap: 16px;
        background-color: white;
        padding: 32px;
        border-radius: var(--border-radius-medium);

        .details-container {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: fit-content;
          padding: 16px;
          border: 1px solid #e0e0e0;
          border-radius: var(--border-radius);
          background-color: #fafafa;

          .title {
            color: var(--color-secondary);
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .gestion--text {
    color: #eb6d81;
  }

  .message-container {
    width: 90%;
    margin-top: 20px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    background-color: #fff;
  }

  .message-container-text {
    width: 100%;
    text-align: center;
    font-size: larger;
    font-weight: 900;
  }

  .info-title {
    font-size: 0.9rem;
    font-weight: 900;
    color: #000000;
  }

  .info-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: #5f5f5f;
    font-style: italic;
  }

  .info-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-top: 20px;
  }

  .added-fees {
    font-size: 0.7em;
    font-weight: 500;
    color: #000;
  }
}