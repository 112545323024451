.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px;

  .btn-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 16px;
  }
}
