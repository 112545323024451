.carrito-height {
  max-height: calc(100vh - 225px);
  overflow-y: scroll;
}

.resume-height {
  max-height: calc(100vh - 220px);
  overflow-y: scroll;
}

.is-selected {
  margin: auto;
  border-bottom: 1px solid #e96e81;
  width: 60%;
}

.tienda-height {
  min-height: calc(100vh - 300px);
  overflow-y: scroll;
}

.products-height {
  height: calc(100vh - 162px);
  overflow-y: scroll;
}

.products-height-mobile {
  height: calc(100vh - 257px);
  overflow-y: scroll;
}

.product-height {
  height: calc(100vh - 115px);
  overflow-y: scroll;
}

.product-height-mobile {
  height: calc(100vh - 110px);
  overflow-y: scroll;
}

.product-card {
  height: fit-content;
  background-color: white;
  border: 1px solid #dddddd;
  padding: 12px;
  border-radius: 24px;
}

.product-image {
  width: 100%;
  object-fit: contain;
  border-radius: 12px;
}

.buy-button p {
  padding: 0px 12px !important;
}

.Address-Input {
  margin-top: 3px;
  border-radius: 34px;
  height: 42px;
  border: 1px solid #cccccc;
  background-color: white;
  font-weight: 300;
  min-width: 200px;
}

.Address-Input-Small {
  margin-top: 3px;
  border-radius: 34px;
  height: 42px;
  border: 1px solid #cccccc;
  background-color: white;
  font-weight: 300;
}

/*PRODUCT */
.product-title {
  font-size: 32px;
}

.product-price {
  font-size: 32px;
  color: #28337d !important;
}

.product-item-card {
  border: 1px solid #eeeeee;
  border-radius: 15px;
}

.product-card-inner-container {
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.product-item-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 24px;
}

.product-minHeight {
  min-height: 40px;
}

/* CARRITO */
.direction-container {
  border: 1px solid #dddddd;
  background-color: #f6f6f6;
  border-radius: 12px;
  padding: 12px;
  height: fit-content;
}

.direction-text {
  color: #808080;
}

.item-cart-card {
  border-radius: 12px;
  box-shadow: 3px 3px 3px #dddddd;
}

.item-cart-card-Gray {
  border-radius: 12px;
  box-shadow: 3px 3px 3px #dddddd;
  border: 1px solid #dddddd;
}

.item-cart-image {
  object-fit: contain;
  width: 150px;
  height: 150px;
  border-radius: 6px;
}

.total-cart-container {
  border: 1px solid #dddddd;
  background-color: white;
  border-radius: 6px;
  height: 400px;
}

.total-cart-container-small {
  border: 1px solid #dddddd;
  background-color: white;
  border-radius: 6px;
}

.edit-button {
  font-size: 12px !important;
}

.cart-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 6px 6px;
}

.symbol {
  font-size: 24px;
  margin-top: -7px;
}

/* MODAL */
.recurrent-buy-product {
  border: 1px solid #dddddd;
  border-radius: 12px;
}

.recurrent-buy-image {
  width: 80px;
  height: 80px;
  border-radius: 12px;
}

/* SLIDER */
.dots {
  background-color: #ffffff;
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}

.dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}

.dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.dots li button:hover,
.dots li button:focus {
  outline: none;
}

.dots li button:hover:before,
.dots li button:focus:before {
  opacity: 1;
}

.dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '•';
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dots li.slick-active button:before {
  opacity: 0.75;
  color: red;
}

.card-payment-label {
  color: #b2637f !important;
  font-weight: 400;
  font-size: 14px;
  margin-left: 6px;
}

.card-payment-input {
  border-radius: 34px;
  border: 1px solid #cccccc;
  background-color: white;
  font-weight: 300;
  padding: 12px;
  margin-bottom: 6px;
}

.card-inputs-flex-container {
  display: flex;
  gap: 82px;
}

.card-inputs-flex-container-Tiny {
  display: flex;
  gap: 46px;
}

.error-message {
  color: red;
  font-size: 12px;
}

.input-container-top {
  position: relative;
  margin-bottom: 38px;
}

.input-container {
  position: relative;
  margin-bottom: 18px;
}
