.image-gallery {
  width: clamp(270px, 98%, 500px);
  height: fit-content;
  /* width: 100%; */
}

.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  width: 100%;
  gap: 16px;
}
