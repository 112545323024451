.informeDetallado-height {
  max-height: calc(100vh - 225px);
  overflow-y: scroll;
}

.tratamiento-container {
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 14px;
}

.informe-button p {
  font-size: 12px;
}

.servicios-container {
  border: 1px solid #d6d6e5;
  border-radius: 8px;
}

.item-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.item-image-pedido {
  width: 125px;
  height: 125px;
  object-fit: contain;
}

/* /PAGO RESUMEN*/
.pago-resumen {
  width: 596px;
  margin: 0px auto;
}

.pago-resumen-container {
  border: 1px solid #d6d6e5;
  border-radius: 12px;
}

.width-600 {
  width: 600px;
}

.checkbox-check-properties {
  color: #0dd345;
  top: -2px;
  right: -2px;
}

.checkbox-white-properties {
  top: -2px;
  right: -2px;
}
