.cls-1 {
  fill: #eb6d81;

  /* @media (prefers-color-scheme: dark) {
    fill: #fff;
  } */
}

.cls-1,
.cls-2,
.cls-3,
.cls-4 {
  stroke-width: 0px;
}

.cls-2 {
  fill: #29327d;

  /* @media (prefers-color-scheme: dark) {
    fill: #fff;
  } */
}

.cls-3 {
  fill: #70706f;

  /* @media (prefers-color-scheme: dark) {
    fill: #fff;
  } */
}

.cls-4 {
  fill: url(#Nueva_muestra_de_degradado_15);

  /* @media (prefers-color-scheme: dark) {
    fill: #fff;
  } */
}

.no_footer_text {
  .main_text {
    translate: 0 20%;
  }
  .footer_text {
    display: none;
  }
}
