.main-container {
  --popup-max-height: 90vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  z-index: 999;
  background-color: color-mix(in srgb, black 20%, transparent);

  .popup {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: min(800px, 98vw);
    min-width: 270px;
    height: fit-content;
    max-height: var(--popup-max-height);
    background-color: #fff;
    padding: var(--popup-padding);
    padding-top: 0;
    border-radius: 30px;
    box-shadow: 0px 4px 10px color-mix(in srgb, #000 10%, transparent),
      0px 6px 15px color-mix(in srgb, #000 10%, transparent);
    overflow: hidden;

    &.popup__xl {
      max-width: 98vw;
    }

    .popup--header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: var(--popup-padding);
      padding-bottom: 8px;
      /* justify-content: space-between; */
      gap: 32px;

      .popup--header--title {
        font-size: 28px;
      }

      .poput--header--close {
        display: grid;
        place-content: center;
        margin-left: auto;
        padding: 10px;
        border-radius: 9999px;
        aspect-ratio: 1/1;
        cursor: pointer;
        transition: background 0.15s ease-in;
        height: 24px;

        &:hover {
          background-color: color-mix(in srgb, #000 8%, transparent);
        }

        &:active {
          background-color: color-mix(in srgb, #000 15%, transparent);
          transition: background 0.05s ease-in;
        }
      }
    }

    .popup--body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: fit-content;
      min-width: 100%;
      margin-top: 8px;
      overflow: hidden;
      gap: 16px;

      width: 100%;

      .popup--body--content {
        overflow-x: hidden;
        overflow-y: auto;
        flex-grow: 1;
        width: fit-content;

        width: 100%;
      }

      .popup--body--buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;

        & > * {
          width: 100%;
        }

        @media screen and (width > 650px) {
          flex-direction: row;

          /* & > * {
            width: 100%;
          } */
        }
      }
    }
  }
}
