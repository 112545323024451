.personas-card {
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  flex-direction: row;
  padding: 12px;
  border-radius: 12px;
  background-color: #ffffff;
  cursor: pointer;

  &.personas-card--border {
    border: 1px solid #dddddd;
  }

  &.personas-card--selected {
    background-color: #e96e810d;
    border: 1px solid #fc0e0e;
  }
}

.text-container {
  display: flex;
}

.personas-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  &.personas-texts--subtitle {
    font-size: 14px;
    line-height: 20px;
  }

  &.personas-text--title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  &.personas-text--extra {
    color: #fc0e0e;
  }
}
