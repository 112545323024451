.main-container {
  width: 100%;
  min-width: 275px;
  height: 100%;

  @media screen and (width >= 769px) {
    background-color: unset;
  }

  .body-container {
    width: 100%;
    padding: 0px;
    overflow: hidden;

    @media screen and (width >= 769px) {
      /* height: calc(100% - 70px); */
      padding: 20px;
      /* margin-top: 70px; */
    }

    .reports-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      padding: 10px;
      margin-top: 90px;
      padding-bottom: 110px;

      @media screen and (width >= 769px) {
        margin-top: 0px;
        padding-bottom: 50px;
      }
    }
  }
}
