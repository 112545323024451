.notifications_button_container {
  position: relative;
}

.bell_container {
  position: relative;
  display: grid;
  place-items: center;
  min-width: 48px;
  min-height: 48px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 2px 5px color-mix(in srgb, #000 10%, transparent),
    0px 4px 10px color-mix(in srgb, #000 10%, transparent);
  cursor: pointer;
  transition: box-shadow 0.15s ease-in, scale 0.15s ease-in, background 0.15s ease-in;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(137deg, rgba(48, 53, 123, 1) 0%, rgba(208, 110, 128, 1) 100%);
    border-radius: 50%;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.15s ease-in;
  }

  &.bell_container-disabled {
    cursor: not-allowed;
  }

  &:not(.bell_container-disabled) {
    &:hover {
      /* box-shadow: 0px 3px 7.5px color-mix(in srgb, #000 12%, transparent),
        0px 6px 15px color-mix(in srgb, #000 12%, transparent);
      scale: 1.01; */
    }

    &:active,
    &.bell_container-clicked,
    &:hover {
      background-color: color-mix(in srgb, #000 4%, transparent);
    }

    &:active,
    &.bell_container-clicked {
      box-shadow: 0px 1px 2.5px color-mix(in srgb, #000 12%, transparent),
        0px 2px 5px color-mix(in srgb, #000 12%, transparent);
      scale: 0.99;
      transition: box-shadow 0.05s ease-in, scale 0.05s ease-in;

      &:after {
        /* opacity: 1; */
      }
    }
  }

  .bell_icon {
    width: 22px;
    height: 22px;

    &.bell_icon-colorized {
      /* color: white; */
    }
  }
}

.link-notificaciones {
  color: rgba(128, 128, 128, 1);
  padding: 12px 12px 0px 12px;
  text-align: center;
  cursor: pointer;
}
