.login-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: fit-content;
}

@media screen and (width <= 700px) {
  .login-form {
    height: 100%;
  }
}
