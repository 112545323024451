.icon-box {
  display: grid;
  place-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: color-mix(in srgb, #000 8%, transparent);

  .document-card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;

    color: black;
    cursor: pointer;
  }
}
