.container {
	background-color: #ffffff;
	padding: 2%;
	padding-top: 100px;
	border-radius: 8px;
	color: #333;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-items: center;
	justify-content: center;

	@media screen and (width >= 769px) {
		padding-top: 20px;
	}
  }
  

  .title {
	color: var(--color-secondary);
	font-size: 28px;
	margin-bottom: 20px;
	text-align: center;
  }
  
  .paragraph {
	margin-bottom: 15px;
  }
  
  .listItem {
	margin-bottom: 10px;
	padding-left: 20px;
  }
  
  .listItem::before {
	content: "•";
	color: var(--color-secondary);
	margin-right: 10px;
  }
  
  .link {
	color: var(--color-secondary);
	text-decoration: none;
  }
  