.notificaciones-height {
  max-height: calc(100vh - 280px);
  overflow-y: scroll;
}

.Notificaciones-Arrow {
  width: 20px;
  height: 13px;
  margin-top: 12px;
  margin-left: 5px;
}

.Notificaciones-Check {
  width: 32px;
  height: 32px;
  background-color: #e7fbed;
  border-radius: 50%;
}

.Notificaciones-Watch {
  width: 32px;
  height: 32px;
  background-color: #f9eff1;
  border-radius: 50%;
}

.Notificaciones-Icon {
  margin: auto;
  margin-top: 6px;
}

.Notificaciones-Text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.Notificaciones-Comment {
  font-size: 14px;
  font-weight: 200;
  line-height: 20px;
  color: rgba(128, 128, 128, 1);
}

.Notificaciones-Link {
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  color: #28337d;
}

.Notificaciones-Date {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
