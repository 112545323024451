.notifications-container {
  width: fit-content;
  max-width: min(500px, 95vw);
  height: fit-content;
  position: absolute;
  background-color: white;
  top: calc(100% + 10px);
  right: 0;
  box-shadow: 0px 4px 10px color-mix(in srgb, #000 10%, transparent),
    0px 6px 15px color-mix(in srgb, #000 10%, transparent);
  border-radius: 16px;
  z-index: 1;

  .notifications-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 16px;
    padding-bottom: 8px;

    .close_icon {
      padding: 10px;
      border-radius: 500rem;
      aspect-ratio: 1/1;
      cursor: pointer;
      transition: background 0.15s ease-in;

      &:hover {
        background-color: color-mix(in srgb, #000 8%, transparent);
      }

      &:active {
        background-color: color-mix(in srgb, #000 15%, transparent);
        transition: background 0.05s ease-in;
      }
    }
  }

  .notifications_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    height: fit-content;
    max-height: min(350px, 50vh);
    min-height: 150px;
    padding: 16px;
    padding-block: 8px;
    overflow-y: hidden;

    &.notifications-body__few-notifications {
      min-height: 0px;
    }

    .notifications-scroll-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
      overflow: scroll;
    }
  }
}
