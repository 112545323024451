.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .btns-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    width: 100%;
    gap: 16px;
  }
}
