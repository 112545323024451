.main-container {
  width: 100%;
  min-width: 275px;
  height: fit-content;
  padding: 0px;
  padding-top: 100px;

  @media screen and (width >= 769px) {
    padding: 20px;
  }

  .back-arrow-container {
    position: relative;
    display: none;
    flex-direction: row;
    text-align: left;
    gap: 16px;
    padding-block: 10px;

    @media screen and (width >= 769px) {
      display: flex;
    }
  }

  .body-container {
    display: flex;
    flex-direction: column;
    padding-block: 16px;
    gap: 48px;

    .media-container {
      display: flex;
      flex-direction: column;
      gap: 48px;

      .container-ui {
        padding: 32px;
        background-color: #fff;
        border-radius: var(--border-radius-medium);
      }

      .audio-and-btn-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;

        gap: 32px;
        width: 100%;

        .audio-container {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      .images-container {
        /* display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); */
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        .image {
          width: 100%;
          height: auto;
          aspect-ratio: 16 / 9;
          object-fit: cover;
          border-radius: 8px;
        }
      }
    }

    .continue-btn {
      width: clamp(200px, 100%, 230px);
    }
  }
}
