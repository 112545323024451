.services--main-container {
  width: 100%;
  padding-top: 20px;
  padding-inline: 20px;

  .services--inner-container {
    width: 100%;
  }

  .items-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
  }
}
