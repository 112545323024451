.button-Cambiar-Visita-Small {
  font-size: 10px;
  font-weight: 700;
  line-height: 145%;
  letter-spacing: -0.1px;
}

.History-Title {
  position: absolute;
  margin-left: 35px;
}

.tiny-screen-max-width {
  max-width: 125px;
}

.big-screen-max-width {
  max-width: 75%;
}

.img-Small {
  height: 100%;
  margin-top: 8px;
}

.maxAltura {
  max-height: calc(100vh);
  overflow: scroll;
}

.informes-height {
  max-height: calc(100vh - 240px);
  overflow: scroll;
}

.informes-height-mobile {
  max-height: calc(100vh - 100px);
  overflow: scroll;
}

.Notificaciones-Arrow {
  width: 20px;
  height: 13px;
  margin-top: 10px;
  margin-left: 5px;
}

.Personas-Page {
  background-color: #f6f6f6;
  height: 100%;
}

.patientsDiv {
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
}

.Personas-Number {
  width: 28px;
  background-color: #28337d;
  color: white;
  text-align: center;
  padding-top: 4px;
  border-radius: 4px;
}

.Personas-Number-small {
  width: 28px;
  background-color: #28337d;
  color: white;
  text-align: center;
  padding-top: 5px;
  border-radius: 7px;
  font-size: 12px;
}

/* Used in DatosPersonaYServicio and Personas */
.Personas-Card {
  background-color: #ffffff;
}

/* Used in Informes and Visitas */
.Personas-Card-Gray {
  background-color: #f6f6f6;
}

/* Used in Personas */
.Personas-Card-Border {
  border: 1px solid #dddddd;
  border-radius: 12px;
}

/* Used in Personas and user-profile */
.Personas-Card-Height {
  height: 380px;
}

/* Used in Personas and user-profile */
.Personas-Card-Height-Small {
  height: 386px;
}

/* Used in Personas */
.Personas-Card-Selected {
  background-color: #e96e810d;
  border: 1px solid #fc0e0e;
}

/* Used in Pedidos, Personas, Servicios and Visitas */
.Personas-Title {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.Personas-Title-small {
  font-size: 14px;
  font-weight: 700;
  line-height: 145%;
  text-align: left;
}

/* Used in Personas */
.Personas-Extra {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #fc0e0e;
}

.Personas-Extra-small {
  font-size: 12px;
  font-weight: 400;
  line-height: 145%;
  text-align: left;
  color: #fc0e0e;
}

.Personas-Label {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--color-secondary);
}

.Personas-Info {
  /* width: 200px; */
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  overflow-wrap: break-word;
}

.Personas-Input {
  margin-top: 3px;
  border-radius: 34px;
  height: 42px;
  border: 1px solid #cccccc;
  background-color: white;
  font-weight: 300;
}

.Personas-Footer {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #444444;
}

.Personas-Footer-small {
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #444444;
}

/* Datos Persona y Servicio */
.Personas-Documentos {
  position: relative;
  width: 100%;
  height: 144px;
  padding: 20px 16px 20px 16px;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
}

.Personas-Documentos-Small {
  position: relative;
  width: 100%;
  height: 95px;
  padding: 20px 16px 20px 16px;
  border-radius: 8px;
  margin: auto;
  cursor: pointer;
}

.Personas-Documentos-Small-End {
  position: relative;
  width: 100%;
  height: 95px;
  padding: 20px 16px 20px 16px;
  border-radius: 8px;
  margin-bottom: 6rem;
  cursor: pointer;
}

.Personas-Documentos-Con-Aviso {
  border: 1px solid #fc0e0e;
  background-color: #fef7f8;
}

.Personas-Documentos-Sin-Aviso {
  border: 1px solid #eeeeee;
  background-color: #ffffff;
}

.Personas-Circle {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: auto;
}

.Personas-Circle-Small {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 15px 0 0;
}

.Personas-Circle-Very-Small {
  position: relative;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 11px 7px 0 0;
}

.Personas-Red {
  background-color: #e96e81;
}

.Personas-Grey {
  background-color: #eeeeee;
}

.Personas-Documentos-Imagen-Little {
  width: 37.5px;
  height: 37.5px;
  margin: auto;
}

.Personas-Documentos-Imagen-Little-Tiny {
  width: 20.5px;
  height: 20.5px;
  margin: auto;
}

.Personas-Documentos-Imagen-Big {
  width: 60px;
  height: 60px;
  margin: auto;
}

.Personas-Documentos-Imagen-Small {
  width: 60px;
  height: 60px;
  margin: 0px 15px 0 0;
}

.Personas-Documentos-Imagen-Fixed {
  position: absolute;
  top: 9px;
  left: 11px;
}

.Personas-Documentos-Imagen-Fixed-Tiny {
  position: absolute;
  top: 6px;
  left: 7px;
}

.Personas-Documentos-Texto {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
}

.Personas-Documentos-Texto-Tiny {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
}

.Personas-Documentation {
  height: 40px;
}

.Personas-Documentation-Title {
  font-size: 18px;
  font-weight: 900;
  line-height: 28px;
  color: #1e1c1c;
}

.Personas-Visitas {
  color: var(--color-secondary);
}

.Personas-Visitas-Menu {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  border-bottom: 1px solid #f5e1e5;
}

.Personas-Visitas-Menu-Small {
  font-size: 14px;
  /* font-weight: 700;
    line-height: 145%;
    text-align: center; */
  border-bottom: 1px solid #f5e1e5;
}

.Personas-Visitas-Menu-Selected {
  width: 100%;
  border-bottom: 2px solid #e96e81;
}

.Personas-Visitas-Menu-Selected-Tiny {
  width: 76%;
  border-bottom: 2px solid #e96e81;
}

.red-Circle-Top-Tiny {
  top: 44px;
}

.border-color {
  border-color: #28337d;
}

.informes-Red {
  border: 1px solid red;
}

.visitas-height-mobile {
  max-height: calc(100vh - 300px);
  overflow: scroll;
}
